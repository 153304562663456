<script setup>
import axios from "axios";
</script>

<script>
export default {
    data() {
        return {
            allOffers: [],
        }
    },
    methods: {
        timeAgo(dateString) {
            const currentDate = new Date(dateString);
            const now = new Date();
            const differenceInMillis = now - currentDate;

            const seconds = Math.floor(differenceInMillis / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            if (days > 1) {
                return `há ${days} dias`;
            } else if (days === 1) {
                return `há 1 dia`;
            } else if (hours > 1) {
                return `há cerca de ${hours} horas`;
            } else if (hours === 1) {
                return `há cerca de 1h`;
            } else if (minutes > 1) {
                return `há cerca de ${minutes} minutos`;
            } else if (minutes === 1) {
                return `há 1 minuto`;
            } else {
                return `agora mesmo`;
            }
        },
        async copyCouponCode(offer) {
            try {
                await navigator.clipboard.writeText(offer.code);

                offer.showCopy = false;
                offer.copySuccess = true;

                setTimeout(() => {
                    offer.copySuccess = false;
                    offer.showCopy = true;
                }, 3000);
            } catch (err) {
                console.error('Erro ao copiar o texto: ', err);
            }
        }
    },
    mounted() {
        const StrapiBaseURL = 'https://strapi-185072-0.cloudclusters.net/api';
        var arrayOffers = [];

        axios.get(`${StrapiBaseURL}/product-offers?populate=*`).then((resp) => {

            var offers = resp.data.data;

            console.log(offers)

            offers.forEach(z => {
                console.log(z)
                arrayOffers.push({
                    id: z.id,
                    createdAt: z.attributes.createdAt,
                    title: z.attributes.Title,
                    price: z.attributes.OfferPrice,
                    code: z.attributes.CouponCode,
                    link: z.attributes.AffiliateURL,
                    platform: z.attributes.FromPlatform,
                    isActive: z.attributes.IsOfferActive,
                    image: StrapiBaseURL.replace('/api', '') + z.attributes.OfferImg.data[0].attributes.url,
                    copySuccess: false,
                    showCopy: true,
                })
            });

            this.allOffers = arrayOffers;
        });
    }

}

</script>

<template>
    <div class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 3xl:grid-cols-5 gap-6 mt-8">
        <!-- v-for -->
        <div v-for="offer in allOffers" :key="offer" class="bg-white flex flex-col gap-y-2 justify-between items-center pt-4 pb-6 px-4 lg:px-6 rounded-2xl shadow-md hover:translate-y-[-8px] transition duration-300 ease">

            <div class="pt-6 pb-4 shrink-0 flex justify-center items-center">
                <img :src="offer.image" :alt="offer.title" class="w-[85%] object-cover object-center rounded-3xl" />
            </div>
            <h2 class="text-base tracking-tight font-normal text-slate-700 text-center mb-0">{{ offer.title }}</h2>
            <div class="mt-2 flex flex-col justify-center items-center gap-y-1">
                <span class="block text-center text-sm text-slate-500 font-normal">A partir de:</span>
                <p class="text-[#5e4d37] text-xl tracking-tight font-bold mb-0">{{ (offer.price / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</p>
                <span class="block text-center text-sm text-slate-500 font-normal">À vista</span>
            </div>

            <div class="w-full mt-2">
                <router-link :to="'/oferta/' + offer.id" target="_blank" class="inline-flex w-full justify-center rounded-md border border-transparent bg-[#0bbf57] py-3 px-5 text-sm font-medium text-white shadow-sm hover:bg-[#1b8746] hover:text-white focus:outline-none focus:ring-2 focus:ring-[#24b45d] focus:ring-offset-2 disabled:opacity-75 cursor-pointer"> Pegar promoção <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="w-5 h-5 ml-2 -mr-1">
                        <path fill-rule="evenodd" d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z" clip-rule="evenodd"></path>
                        <path fill-rule="evenodd" d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z" clip-rule="evenodd"></path>
                    </svg></router-link>
            </div>

            <div v-if="offer.code" @click="copyCouponCode(offer)" class="w-full rounded-lg px-4 py-3.5 border-2 border-dashed border-slate-400 hover:border-teal-600 hover:shadow-md transition duration-300 ease my-2 flex items-center justify-between cursor-pointer">
                <div v-show="offer.showCopy" class="flex justify-between items-center w-full gap-x-1">
                    <div class="coupon-code font-bold text-ellipsis overflow-hidden text-sm">
                        {{ offer.code }}
                    </div>
                    <div class="flex items-center gap-x-1 text-sm text-slate-600 font-normal">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"></path>
                        </svg>
                        Copiar
                    </div>
                </div>
                <div v-if="offer.copySuccess" class="success-copy text-sm font-normal flex h-[20px] items-center justify-center w-full text-slate-700">
                    Copiado com sucesso!
                </div>
            </div>

            <div class="w-full flex items-center justify-end gap-1 text-xs text-slate-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                {{ timeAgo(offer.createdAt) }}
            </div>


        </div>
    </div>
</template>