import { createApp } from 'vue'
import App from './App.vue'
import '@/style.css'
import { createRouter, createWebHistory } from 'vue-router'
import VueRouter from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';

import HomeView from './views/HomeView.vue'
import DisplayContentView from './views/DisplayContentView.vue'
import PageNotFoundView from './views/PageNotFoundView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/oferta/:id',
        name: 'id',
        component: DisplayContentView
    },
    {
        path: '/404',
        name: '404',
        component: PageNotFoundView
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})


axios.defaults.baseURL = process.env.APP_BASE_URL;

const app = createApp(App)

app.use(router);
app.use(VueRouter);
app.use(VueAxios, axios);
app.config.globalProperties.$axios = axios;
app.mount('#app');
